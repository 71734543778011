import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'next-i18next';
import { usePlausible } from 'next-plausible';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { showDefaultErrorSnackbarMessage } from '../../utils/showDefaultErrorSnackbarMessage';
import SignUpButton from '../structure/SignUpButton';
import { useRouter } from 'next/router';
import { UsersContext } from '../hooks/useUser';
import LargeWhiteButton from '../structure/LargeWhiteButton';
import errorCodes, {
  ERROR_CREATE_DEMO,
} from '@cr/common/src/config/error-codes';

const CREATE_DEMO = gql`
  mutation createDemo($locale: String!, $recaptchaToken: String!) {
    createDemo(locale: $locale, recaptchaToken: $recaptchaToken)
  }
`;

const CreateDemoButton = ({ largeWhite = false, ...props }) => {
  const { t } = useTranslation(['sign-up', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { login } = useContext(UsersContext);
  const plausible = usePlausible();
  const router = useRouter();

  const [counter, setCounter] = useState(null);
  useEffect(() => {
    if (!counter || counter >= 100) return;
    setTimeout(() => setCounter(counter + 1), 80);
  }, [counter]);

  const [createDemo, { data, loading }] = useMutation(CREATE_DEMO, {
    onError: () =>
      showDefaultErrorSnackbarMessage({
        t,
        enqueueSnackbar,
        code: ERROR_CREATE_DEMO,
      }),
  });
  const onClick = async () => {
    setCounter(1);
    plausible('Demo');

    const {
      data: { createDemo: userCookie },
    } = await createDemo({
      variables: {
        locale: router.locale,
        recaptchaToken: await executeRecaptcha('demo'),
      },
    });
    await login({
      token: userCookie,
      afterLoginDestination: '/dashboard?demo=true',
    });
  };

  if (largeWhite)
    return (
      <LargeWhiteButton
        sx={{
          height: 64,
          width: 130,
          '&.Mui-disabled': { backgroundColor: 'primary.P' },
        }}
        label={counter ? `${counter} %` : 'Demo'}
        onClick={onClick}
        disabled={loading || !!data}
      />
    );
  return (
    <SignUpButton
      disabled={loading || !!data}
      label={
        counter ? `${counter} %` : t('Live Demo testen', { ns: 'sign-up' })
      }
      href={null}
      onClick={onClick}
      {...props}
    />
  );
};

export default CreateDemoButton;
