import { Divider, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import React from 'react';
import IcContactChat from '../media/icons/ic-contact-chat.svg';
import { ROUTES } from '../utils/AppRoutes';
import Link from './Link';
import AccentButton from './structure/AccentButton';
import IcSocialYoutube from '../media/icons/ic-social-youtube.svg';
import IcSocialTwitter from '../media/icons/ic-social-twitter.svg';
import IcSocialTelegram from '../media/icons/ic-socia-media-telegram.svg';

const { publicRuntimeConfig } = getConfig();

const Footer = () => {
  const { t } = useTranslation('common');
  const { palette } = useTheme();
  const router = useRouter();

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  if (!isDesktop) return null;

  return (
    <Container maxWidth="lg">
      <Divider />

      <Box sx={{ pt: 4, pb: 4 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} sm={6}>
            <Grid container direction="row" spacing={4} alignItems="center">
              <Grid item>
                <Typography variant="subtitle2">
                  © chain.report 2021-2023
                </Typography>
              </Grid>

              <Grid item>
                <Divider
                  orientation="vertical"
                  sx={{ width: '1px', height: '1.75rem', color: 'primary.P' }}
                />
              </Grid>

              <Grid item>
                <Typography variant="subtitle1">
                  {t('Krypto-Steuererklärung in wenigen Minuten.')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item>
                <AccentButton
                  variant="text"
                  size="small"
                  startIcon={
                    <IcSocialTwitter
                      style={{
                        width: 20,
                        height: 20,
                        stroke: palette.info.main,
                      }}
                    />
                  }
                  onClick={() => router.push(publicRuntimeConfig.twitter)}
                >
                  X
                </AccentButton>
              </Grid>

              <Grid item>
                <AccentButton
                  variant="text"
                  size="small"
                  startIcon={
                    <IcSocialYoutube
                      style={{
                        width: 20,
                        height: 20,
                        stroke: palette.info.main,
                      }}
                    />
                  }
                  onClick={() => router.push(publicRuntimeConfig.youtube)}
                >
                  YouTube
                </AccentButton>
              </Grid>

              <Grid item>
                <AccentButton
                  variant="text"
                  size="small"
                  startIcon={
                    <IcSocialTelegram
                      style={{
                        width: 20,
                        height: 20,
                        stroke: palette.info.main,
                      }}
                    />
                  }
                  onClick={() => router.push(publicRuntimeConfig.telegram)}
                >
                  Telegram
                </AccentButton>
              </Grid>

              <Grid item>
                <AccentButton
                  variant="text"
                  size="small"
                  startIcon={
                    <IcContactChat
                      style={{
                        width: 20,
                        height: 20,
                        stroke: palette.info.main,
                      }}
                    />
                  }
                  onClick={() => router.push(publicRuntimeConfig.contactEmail)}
                >
                  E-Mail
                </AccentButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export const FooterLanding = () => {
  const { t } = useTranslation('common');
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const links = (
    <>
      <Grid item>
        <Link href={ROUTES.IMPRINT} color="primary.M" variant="subtitle1">
          {t('Impressum')}
        </Link>
      </Grid>

      <Grid item>
        <Link href={ROUTES.PRIVACY} color="primary.M" variant="subtitle1">
          {t('Datenschutzrichtlinien')}
        </Link>
      </Grid>

      <Grid item>
        <Link href={ROUTES.AGB} color="primary.M" variant="subtitle1">
          {t('Allgemeine Geschäftbedingungen')}
        </Link>
      </Grid>
    </>
  );

  if (!isDesktop) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        style={{ margin: '-1rem 0 4rem 0' }}
        spacing={2}
      >
        <Grid item>
          <Typography color="custom.white" style={{ fontSize: 12 }}>
            © chain.report 2021-2023
          </Typography>
        </Grid>

        {links}
      </Grid>
    );
  }

  return (
    <Grid
      md={8}
      container
      item
      justifyContent="space-between"
      alignItems="center"
      style={{ margin: '2rem 0 9rem 0' }}
    >
      <Grid item md={3} alignItems="center">
        <Typography variant="subtitle1" color="custom.white">
          © chain.report 2021-2023
        </Typography>
      </Grid>

      <Grid item md={9}>
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="flex-end"
        >
          {links}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
