// Styles for the carousel
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import getConfig from 'next/config';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FooterLanding } from '../components/Footer';
import { UsersContext } from '../components/hooks/useUser';
import IconButton from '../components/IconButton';
import MarketingBannerSection from '../components/structure/MarketingBannerSection';
import MarketingMotivationBanner from '../components/structure/MarketingMotivationBanner';
import SignUpButton from '../components/structure/SignUpButton';
import ArrowLeft from '../media/icons/ic-arrows-left.svg';
import ArrowRight from '../media/icons/ic-arrows-right.svg';
import SliderAffiliate from '../media/images/AffiliateProgramm.png';
import AppScreensBackground from '../media/images/appscreens-desktop-background.png';
import AppScreensMobileBackground from '../media/images/appscreens-mobile-background.png';
import CoinBanner from '../media/images/coinBanner.svg';
import SliderDashboard from '../media/images/Dashboard.png';
import LogoLight from '../media/images/LogoLight.png';
import MenTaxGenerator from '../media/images/MenTaxGenerator.png';
import MenTaxGeneratorMobile from '../media/images/MenTaxGeneratorMobile.png';
import StepOne from '../media/images/Step1.png';
import StepTwo from '../media/images/Step2.png';
import StepThree from '../media/images/Step3.png';
import SliderTaxYear from '../media/images/Steuerjahr.png';
import SliderTransactions from '../media/images/Transaktionen.png';
import SliderWallets from '../media/images/Wallets.png';
import CreateDemoButton from '../components/buttons/CreateDemoButton';
import Onboarding from '../components/Onboarding';
import FortCanningBackground from '../media/images/bck-graident.png';
const { publicRuntimeConfig } = getConfig();
import { ROUTES } from '../utils/AppRoutes';

const IndexPage = () => {
  const { t } = useTranslation('mainpage');
  const { setIsLightVariant } = useContext(UsersContext);
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  useEffect(() => {
    const targetElement = document.getElementById('dark-animation-section');
    const handleBackgroundChange = (entries) => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          document.getElementById('animated-background').style.backgroundColor =
            'black';
          document.getElementById('exchange-transition-text').style.color =
            'white';
          setIsLightVariant(false);
        }
        if (!isIntersecting) {
          document.getElementById('animated-background').style.backgroundColor =
            'white';
          document.getElementById('exchange-transition-text').style.color =
            'black';
          setIsLightVariant(true);
        }
      });
    };

    let observer;
    if (window.IntersectionObserver) {
      observer = new window.IntersectionObserver(handleBackgroundChange);
      observer.observe(targetElement);
    }

    return () => {
      if (observer) observer.unobserve(targetElement);
      setIsLightVariant(true);
    };
  }, []);

  return (
    <>
      {!isMobile && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            height: 1455,
          }}
        >
          <Image
            src={FortCanningBackground}
            alt="FortCanningBackground"
            priority
            layout="fill"
            objectFit="cover"
            placeholder="blur"
          />
        </div>
      )}

      <div
        id="animated-background"
        style={{ transition: 'background-color 1000ms ease' }}
      >
        <Container maxWidth="lg" style={{ padding: '0 1.5rem' }}>
          <div
            style={{
              position: 'relative',
              height: !isMobile ? '600px' : undefined,
            }}
          >
            <MarketingMotivationBanner textAlign="left" />

            {!isMobile && (
              <div style={{ position: 'absolute', right: 0, top: 200 }}>
                <Image
                  src={MenTaxGenerator}
                  width={552}
                  height={393}
                  priority
                  alt={t(
                    'A happy men tossing coins into a tax calculator machine.'
                  )}
                />
              </div>
            )}
          </div>

          <div style={{ position: 'relative' }}>
            <Onboarding isMobile={isMobile} />
          </div>

          {isMobile && (
            <div
              style={{
                display: 'grid',
                placeItems: 'center',
                width: '80%',
                margin: '3.5rem -3rem 7rem -3rem',
              }}
            >
              <Image
                src={MenTaxGeneratorMobile}
                width={400}
                height={284}
                priority
                alt={t(
                  'A happy men tossing coins into a tax calculator machine.'
                )}
              />
            </div>
          )}

          <div style={{ marginTop: 200, zIndex: 0, position: 'relative' }}>
            <Steps />
          </div>

          <div
            style={{
              display: 'grid',
              placeItems: 'center',
              margin: isMobile ? '0 0 5rem 0' : '7.5rem 0 10rem 0',
            }}
          >
            <Typography
              fontWeight="bold"
              textAlign="center"
              style={{
                fontSize: isMobile ? 36 : 48,
              }}
            >
              {t('Krypto Steuererklärung')}
              <br />
              {t('in wenigen Minuten.')}
            </Typography>

            <CreateDemoButton
              sx={{
                margin: isMobile ? '2rem 0 1.5rem 0' : '2rem 0 2.5rem 0',
                height: 64,
              }}
            />
          </div>

          <Coinswallpaper />
          <Exchanges />
          <PromotionSection />

          {/*{!isMobile ? <Tweets /> : null}*/}

          <div id="dark-animation-section">
            <Prices />

            <MobileAppSection />

            <div
              style={{
                position: 'absolute',
                left: 0,
                width: isMobile ? '100vw' : 'calc(100vw - 1.5rem)',
                overflow: 'hidden',
              }}
            >
              <div
                style={
                  isMobile
                    ? {
                        marginLeft: 'calc(-848px / 2 + 50vw)',
                        width: '848px',
                        height: '653px',
                      }
                    : {
                        marginLeft: 'calc(-2056px / 2 + 50vw)',
                        width: '2056px',
                        height: '1689px',
                      }
                }
              >
                {isMobile ? (
                  <Image
                    src={AppScreensMobileBackground}
                    width={848}
                    height={653}
                    priority
                    alt={t('A variety of mobile app screens')}
                  />
                ) : (
                  <Image
                    src={AppScreensBackground}
                    width={2056}
                    height={1689}
                    priority
                    alt={t('A variety of mobile app screens')}
                  />
                )}
              </div>
            </div>

            <div
              style={{
                position: 'relative',
                display: 'grid',
                placeItems: 'center',
                paddingTop: isMobile ? '350px' : '1000px',
              }}
            >
              <MarketingMotivationBanner color="#ffffff" />

              <CreateDemoButton
                sx={{
                  margin: isMobile ? '0.5rem 0 1.5rem 0' : '2.5rem 0 3rem 0',
                  backgroundColor: 'secondary.main',
                  color: 'primary.main',
                  height: 64,
                }}
                hoverStyle={{
                  bgcolor: 'custom.white',
                  color: 'custom.black',
                }}
              />

              <div
                style={
                  isMobile
                    ? { margin: '80px 0 1rem 0' }
                    : { margin: '200px 0 1.5rem 0' }
                }
              >
                <Image
                  src={LogoLight}
                  width={282}
                  height={48}
                  alt={t('A bright logo for our brand.')}
                />
              </div>

              <Typography
                color="primary.I"
                textAlign="center"
                style={{ fontSize: 20 }}
              >
                {t('Krypto-Steuererklärung in wenigen Minuten')}
              </Typography>

              <MarketingBannerSection variant="dark" />

              <FooterLanding />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default IndexPage;

function ExampleScreenCarousel() {
  const { t } = useTranslation('mainpage');
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  // We must modify images to have borderRadius of 32 and box-shadow of (y: 2, x: 2) because we cant set overflow: hidden on the container, because of the next/previous buttons.
  const CAROUSEL_IMAGES = [
    {
      name: 'Dashboard',
      alt: t('example screen of our dashboard'),
      src: SliderDashboard,
    },
    {
      name: 'Transaktionen',
      alt: t('example screen of our transaction overview'),
      src: SliderTransactions,
    },
    {
      name: 'Wallets',
      alt: t('example screen for our crypto exchange wallets'),
      src: SliderWallets,
    },
    {
      name: 'Steuerjahr',
      alt: t('example screen of our tax reports'),
      src: SliderTaxYear,
    },
    {
      name: 'AffiliateProgramm',
      alt: t('example screen of our affiliate programm'),
      src: SliderAffiliate,
    },
  ];

  return (
    <Carousel
      ariaLabel={t('image-carousel')}
      infiniteLoop
      showIndicators={false}
      showStatus={false}
      showThumbs={false}
      renderArrowPrev={(onClick, _, a11yLabel) => (
        <div
          style={{
            left: isMobile ? 'calc(1rem - 15px)' : 'calc(5rem - 27px)',
            bottom: 0,
            top: 0,
            position: 'absolute',
            display: 'grid',
            placeItems: 'center',
            overflow: 'visible',
          }}
        >
          <IconButton
            aria-label={a11yLabel}
            sx={{
              zIndex: 2,
              cursor: 'pointer',
              borderRadius: 25,
              backgroundColor: 'primary.main',
            }}
            onClick={onClick}
          >
            <ArrowLeft
              style={
                isMobile
                  ? { height: 27, width: 27, stroke: 'white' }
                  : { height: 32, width: 32, stroke: 'white' }
              }
            />
          </IconButton>
        </div>
      )}
      renderArrowNext={(onClick, _, a11yLabel) => (
        <div
          style={{
            right: isMobile ? 'calc(1rem - 15px)' : 'calc(5rem - 27px)',
            bottom: 0,
            top: 0,
            position: 'absolute',
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <IconButton
            aria-label={a11yLabel}
            sx={{
              zIndex: 2,
              cursor: 'pointer',
              borderRadius: 25,
              backgroundColor: 'primary.main',
            }}
            onClick={onClick}
          >
            <ArrowRight
              style={
                isMobile
                  ? { height: 27, width: 27, stroke: 'white' }
                  : { height: 32, width: 32, stroke: 'white' }
              }
            />
          </IconButton>
        </div>
      )}
    >
      {CAROUSEL_IMAGES.map(({ name, alt, src }) => (
        <div
          key={name}
          style={{
            padding: isMobile ? '1rem 1rem 1.5rem 1rem' : '5rem 5rem 4rem 5rem',
          }}
        >
          <Image
            src={src}
            alt={alt}
            width={isMobile ? 600 : 1440}
            height={isMobile ? 190 : 600}
            priority
            placeholder="blur"
          />
        </div>
      ))}
    </Carousel>
  );
}

function PromotionSection() {
  const { t } = useTranslation('mainpage');
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  return (
    <>
      <Typography
        variant="h4"
        textAlign="center"
        style={{
          marginTop: isMobile ? '0.5rem' : '3rem',
          marginBottom: '1.5rem',
          zIndex: 2,
          position: 'relative',
        }}
      >
        {/*{t('Steuer-Report in drei einfachen Schritten')}*/}
      </Typography>

      <Grid
        container
        sx={{
          backgroundColor: 'primary.P',
          zIndex: 2,
          position: 'relative',
          padding: 0,
          borderRadius: '8px',
          marginBottom: isMobile ? '3.5rem' : '10.5rem',
        }}
      >
        <ExampleScreenCarousel />

        {isMobile ? (
          <>
            <Grid item md={12} style={{ padding: '0 0 0.5rem 1rem' }}>
              <Typography
                variant="h4"
                style={{ fontSize: 24, marginBottom: '1rem' }}
              >
                {t('Transaktionen')}
              </Typography>

              <Typography>
                {t(
                  'Dashboard where all the magic happens. Get great overview of your trades, withdrawals, deposits and more.'
                )}
              </Typography>
            </Grid>

            <Grid item md={12} style={{ padding: '1rem', width: '100%' }}>
              <CreateDemoButton sx={{ margin: 0 }} fullWidth />
            </Grid>
          </>
        ) : (
          <>
            <Grid item md={6} style={{ padding: '0 0 5rem 5rem' }}>
              <Typography
                variant="h4"
                style={{ fontSize: 48, marginBottom: '1.5rem' }}
              >
                {t('Transaktionen')}
              </Typography>

              <Typography>
                {t(
                  'Dashboard where all the magic happens. Get great overview of your trades, withdrawals, deposits and more.'
                )}
              </Typography>
            </Grid>

            <Grid
              container
              item
              md={6}
              style={{ padding: '0 5rem 0 5rem' }}
              justifyContent="flex-end"
            >
              <CreateDemoButton sx={{ margin: 0, height: 64 }} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

const StepCount = ({ label }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'secondary.main',
        borderRadius: 25,
        display: 'grid',
        placeItems: 'center',
        height: '2.5rem',
        width: '2.5rem',
      }}
    >
      <Typography fontWeight="bold" style={{ fontSize: 18 }}>
        {label}
      </Typography>
    </Box>
  );
};

const StepNotation = ({ children }) => {
  const isTablet = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  return (
    <Box
      sx={{
        backgroundColor: 'primary.Q',
        borderRadius: '8px',
        padding: isTablet ? '0.25rem 0.5rem' : '0.5rem 1rem',
        margin: '0.5rem',
      }}
    >
      <Typography textAlign="center" fontWeight="bold">
        {children}
      </Typography>
    </Box>
  );
};

const defineStepTitle =
  (fontSize) =>
  // eslint-disable-next-line react/display-name
  ({ children }) =>
    (
      <Typography
        variant="h5"
        textAlign="center"
        style={{ margin: '0.5rem 0 2rem 0', fontSize }}
      >
        {children}
      </Typography>
    );

function Steps() {
  const { t } = useTranslation('mainpage');

  const isTablet = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const StepTitle = defineStepTitle(isMobile ? 36 : 48);
  const Step2Image = () => (
    <Grid
      item
      xs={12}
      md={6}
      style={{ display: 'grid', placeItems: 'center', overflow: 'hidden' }}
    >
      <Image
        src={StepTwo}
        priority
        width={isMobile ? 350 : 420}
        height={isMobile ? 293 : 352}
        alt={t('Man looking at a graph of his crypto assets.')}
      />
    </Grid>
  );

  const Step2Description = () => (
    <Grid item xs={12} md={6}>
      <div
        style={{
          display: 'grid',
          placeItems: 'center',
          margin: '1.5rem 0 3rem 0',
        }}
      >
        <StepCount label="2" />
        <StepTitle>{t('Überblick erhalten')}</StepTitle>
        <StepNotation>{t('Aktuelle Auswertungen zum Portfolio')}</StepNotation>
        <StepNotation>{t('Manuelle Korrekturmöglichkeiten')}</StepNotation>
        <StepNotation>{t('Optionen zur Steueroptimierung')}</StepNotation>
        <StepNotation>{t('Übersichtliche Fehleranalyse')}</StepNotation>
      </div>
    </Grid>
  );

  const { user } = useContext(UsersContext);
  const cta = user ? t('Jetzt kaufen') : t('Kostenlos starten');

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <SignUpButton
          sx={{
            width: '17.5rem',
            color: 'custom.white',
            border: '1px solid transparent',
            borderColor: 'primary.D',
            margin: 0,
          }}
          label={cta}
          href={user ? ROUTES.REPORTS : ROUTES.SIGNUP}
          hoverStyle={{
            bgcolor: 'custom.white',
            color: 'custom.black',
          }}
        />
      </div>

      <Typography
        variant="h4"
        textAlign="center"
        style={{ margin: isTablet ? '1.5rem' : '3rem' }}
      >
        {t('Steuer-Report in drei einfachen Schritten')}
      </Typography>

      <Grid container flexDirection="row-reverse">
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: 'grid', placeItems: 'center', overflow: 'hidden' }}
        >
          <Image
            src={StepOne}
            priority
            width={isMobile ? 350 : 420}
            height={isMobile ? 217 : 261}
            alt={t('Man putting in a giant power plug.')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <div
            style={{
              display: 'grid',
              placeItems: 'center',
              margin: '1.5rem 0 3rem 0',
            }}
          >
            <StepCount label="1" />
            <StepTitle>{t('Transaktionen importieren')}</StepTitle>
            <StepNotation>
              {t('Automatisierte Synchronisation über Schnittstellen')}
            </StepNotation>
            <StepNotation>{t('Manuelle Eingabe')}</StepNotation>
            <StepNotation>{t('Datei Import')}</StepNotation>
          </div>
        </Grid>

        {isTablet ? (
          <>
            <Step2Image /> <Step2Description />
          </>
        ) : (
          <>
            <Step2Description /> <Step2Image />
          </>
        )}

        <Grid
          item
          xs={12}
          md={6}
          style={{ display: 'grid', placeItems: 'center', overflow: 'hidden' }}
        >
          <Image
            src={StepThree}
            priority
            width={isMobile ? 350 : 454}
            height={isMobile ? 249 : 323}
            alt={t('Man looking a printer working.')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <div
            style={{
              display: 'grid',
              placeItems: 'center',
              margin: '1.5rem 0 3rem 0',
            }}
          >
            <StepCount label="3" />
            <StepTitle>{t('Steuerreport herunterladen')}</StepTitle>
            <StepNotation>
              {t('Der eigenen Steuererklärung beilegen')}
            </StepNotation>
            <StepNotation>{t('Dem Steuerberater übergeben')}</StepNotation>
            <StepNotation>{t('Fertige Auswertung als PDF')}</StepNotation>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

function Coinswallpaper() {
  const { t } = useTranslation('mainpage');
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        width: 'calc(100vw - 1rem)',
        overflow: 'hidden',
      }}
    >
      <div
        style={
          isMobile
            ? {
                width: 822,
                height: 222,
                marginLeft: 'calc(-411px / 2 + 50vw)',
              }
            : {
                width: 2704,
                height: 601,
                marginLeft: 'calc(-1309px / 2 + 50vw)',
                marginBottom: 50,
              }
        }
      >
        {isMobile ? (
          <CoinBanner width={411} height={193} />
        ) : (
          <CoinBanner width={1309} height={617} />
        )}
      </div>
    </div>
  );
}

function Exchanges() {
  const { t } = useTranslation('mainpage');
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  return (
    <div
      id="exchange-transition-text"
      style={{
        display: 'grid',
        placeItems: 'center',
        marginTop: isMobile ? 280 : 660,
        marginBottom: isMobile ? '1rem' : '3rem',
      }}
    >
      <Typography
        variant="h1"
        textAlign="center"
        style={{
          fontSize: isMobile ? 32 : 80,
          maxWidth: isMobile ? 200 : 500,
          marginBottom: isMobile ? '1rem' : '2rem',
        }}
      >
        {t('Börsen')}
      </Typography>

      <Typography
        variant="body2"
        textAlign="center"
        color="primary.G"
        style={{
          maxWidth: 550,
          fontSize: isMobile ? 14 : 20,
          marginBottom: '200px',
        }}
      >
        {t('Für die Mehrheit der Börsen bieten wir...')}
      </Typography>

      {/*<AccentButton*/}
      {/*  color={isLightVariant ? 'secondary' : 'primary'}*/}
      {/*  size="large"*/}
      {/*  href={ROUTES.EXCHANGES}*/}
      {/*  sx={{*/}
      {/*    margin: isMobile ? '2rem 0' : '4rem 0',*/}
      {/*    height: 64,*/}
      {/*    ...(isLightVariant*/}
      {/*      ? {*/}
      {/*          backgroundColor: 'custom.white',*/}
      {/*          border: '2px solid transparent',*/}
      {/*          borderColor: 'primary.N',*/}
      {/*        }*/}
      {/*      : {*/}
      {/*          color: 'custom.white',*/}
      {/*          border: '1px solid transparent',*/}
      {/*          borderColor: 'primary.D',*/}
      {/*          ':hover': {*/}
      {/*            bgcolor: 'custom.white',*/}
      {/*            color: 'custom.black',*/}
      {/*          },*/}
      {/*        }),*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {t('Alle Börsen anzeigen')}*/}
      {/*</AccentButton>*/}
    </div>
  );
}

const ListItemCenter = ({ label, highlight }) => {
  return (
    <ListItem style={{ marginBottom: '1rem', padding: '0 1.5rem 0 1.5rem' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ListItemIcon
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '10px',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              placeItems: 'center',
              backgroundColor: highlight ? 'secondary.main' : 'primary.C',
              padding: '2px',
              borderRadius: 25,
            }}
          >
            <CheckIcon style={{ fill: highlight ? 'black' : 'white' }} />
          </Box>
        </ListItemIcon>

        <ListItemText
          primary={<Typography fontWeight="bold">{label}</Typography>}
          sx={{ color: highlight ? 'custom.white' : 'primary.I' }}
        />
      </div>
    </ListItem>
  );
};

function Prices() {
  const { t } = useTranslation('mainpage');
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));
  const router = useRouter();
  const { user } = useContext(UsersContext);

  return (
    <div style={{ marginTop: isMobile ? '5rem' : '16.5rem' }}>
      <Typography
        variant="h4"
        textAlign="center"
        sx={{ color: 'secondary.main' }}
      >
        {t('Pakete')}
      </Typography>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            marginTop: isMobile ? '1.5rem' : '3rem',
            padding: isMobile ? 0 : 8,
          }}
        >
          <Card sx={{ backgroundColor: 'custom.black' }}>
            <CardContent style={{ height: !isMobile && '45rem' }}>
              <Typography
                variant="h1"
                textAlign="center"
                color="custom.white"
                style={{ fontSize: isMobile ? 24 : 32 }}
              >
                Investor
              </Typography>
              <Typography
                variant="h1"
                textAlign="center"
                sx={{ color: 'secondary.main', margin: '1rem 0 0 0' }}
              >
                59€
              </Typography>
              <Typography
                variant="subtitle1"
                textAlign="center"
                color="primary.I"
                style={{ fontSize: 16 }}
              >
                per year
              </Typography>

              <Divider
                sx={{
                  height: '1px',
                  borderColor: 'primary.C',
                  margin: isMobile ? '24px 0 32px 0' : '33px 50px 48px 50px',
                }}
                variant="middle"
              />

              <List dense>
                <ListItemCenter label={t('5.000 Transaktionen')} />
                <ListItemCenter label={t('Datei & API Import')} />
                <ListItemCenter label={t('Portfoliomanagement')} />
                <ListItemCenter
                  label={t('Echtzeit-Analyse deines Portfolios')}
                />
                <ListItemCenter
                  label={t('Steuerliche Auswertungen, Charts & Statistiken')}
                />
                <ListItemCenter label={t('Verschlüsseltes Archiv')} />
              </List>
            </CardContent>
            <CardActions style={{ display: 'grid', placeItems: 'center' }}>
              <SignUpButton
                sx={{
                  width: '17.5rem',
                  color: 'custom.white',
                  border: '1px solid transparent',
                  borderColor: 'primary.D',
                  margin: 0,
                }}
                href={user ? ROUTES.REPORTS : ROUTES.SIGNUP}
                hoverStyle={{
                  bgcolor: 'custom.white',
                  color: 'custom.black',
                }}
              />
            </CardActions>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          style={{ marginTop: '3rem', padding: isMobile ? 0 : 8 }}
        >
          <Card
            sx={{
              border: '1px solid black',
              borderColor: 'secondary.main',
              borderRadius: '8px',
              backgroundColor: 'custom.black',
            }}
          >
            <CardContent>
              <Typography
                variant="h1"
                textAlign="center"
                color="custom.white"
                style={{ fontSize: isMobile ? 24 : 32 }}
              >
                Trader
              </Typography>
              <Typography
                variant="h1"
                textAlign="center"
                sx={{ color: 'secondary.main', margin: '1rem 0 0 0' }}
              >
                199€
              </Typography>
              <Typography
                variant="subtitle1"
                textAlign="center"
                color="primary.I"
                style={{ fontSize: 16 }}
              >
                per year
              </Typography>

              <Divider
                sx={{
                  height: '1px',
                  borderColor: 'secondary.main',
                  margin: isMobile ? '32px 0' : '55px 50px 48px 50px',
                }}
                variant="middle"
              />

              <List dense>
                <ListItemCenter highlight label={t('25.000 Transaktionen')} />
                <ListItemCenter highlight label={t('Datei & API Import')} />
                <ListItemCenter highlight label={t('Portfoliomanagement')} />
                <ListItemCenter
                  highlight
                  label={t('Echtzeit-Analyse deines Portfolios')}
                />
                <ListItemCenter
                  highlight
                  label={t('Steuerliche Auswertungen, Charts & Statistiken')}
                />
                <ListItemCenter highlight label={t('Verschlüsseltes Archiv')} />
                <ListItemCenter highlight label={t('Echtzeit Chat-Support')} />
                <ListItemCenter
                  highlight
                  label={t('CSV-Export von Transaktionen')}
                />
              </List>
            </CardContent>
            <CardActions style={{ display: 'grid', placeItems: 'center' }}>
              <SignUpButton
                sx={{
                  width: '17.5rem',
                  margin: isMobile ? 0 : '4rem 0',
                  backgroundColor: 'secondary.main',
                  color: 'primary.main',
                }}
                href={user ? ROUTES.REPORTS : ROUTES.SIGNUP}
                hoverStyle={{ bgcolor: 'custom.white', color: 'custom.black' }}
              />
            </CardActions>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          style={{ marginTop: '3rem', padding: isMobile ? 0 : 8 }}
        >
          <Card sx={{ backgroundColor: 'custom.black' }}>
            <CardContent style={{ height: !isMobile && '45rem' }}>
              <Typography
                variant="h1"
                textAlign="center"
                color="custom.white"
                style={{ fontSize: isMobile ? 24 : 32 }}
              >
                {t('Professional')}
              </Typography>
              <Typography
                variant="h3"
                textAlign="center"
                color="custom.white"
                style={{ margin: '1rem 0 0 0' }}
              >
                from 899€
              </Typography>
              <Typography
                variant="subtitle1"
                textAlign="center"
                color="primary.I"
                style={{ fontSize: 16 }}
              >
                {t('VIP Support')}
              </Typography>

              <Divider
                sx={{
                  height: '1px',
                  borderColor: 'primary.C',
                  margin: isMobile ? '24px 0 32px 0' : '55px 50px 48px 50px',
                }}
                variant="middle"
              />

              <List dense>
                <ListItemCenter label={t('350.000 Transaktionen')} />
                <ListItemCenter
                  label={t('Individueller Betreuung und Hilfe')}
                />
                <ListItemCenter
                  label={t('VIP Support beim Portfolio Tracking')}
                />
                <ListItemCenter label={t('Telegram VIP Gruppe')} />
                <ListItemCenter label={t('Alle Premium Funktionen')} />
              </List>
            </CardContent>
            <CardActions style={{ display: 'grid', placeItems: 'center' }}>
              <SignUpButton
                sx={{
                  width: '17.5rem',
                  color: 'custom.white',
                  border: '1px solid transparent',
                  borderColor: 'primary.D',
                  margin: 0,
                }}
                hoverStyle={{
                  bgcolor: 'custom.white',
                  color: 'custom.black',
                }}
                label={t('Kontakt aufnehmen')}
                onClick={() => router.push(publicRuntimeConfig.contactEmail)}
                href={false}
              />
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

// const AppStorePoster = ({ logo, description, appLabel }) => {
//   return (
//     <Grid
//       sx={{
//         backgroundColor: 'primary.B',
//         borderRadius: '8px',
//         margin: '0.5rem',
//         padding: '0.75rem 0',
//         gap: '0.5rem',
//         maxWidth: 400,
//       }}
//       container
//       item
//       md={3}
//       justifyContent="center"
//       alignItems="center"
//     >
//       {logo}
//       <div>
//         <Typography color="custom.white" style={{ margin: '0', fontSize: 12 }}>
//           {description}
//         </Typography>
//         <Typography
//           color="custom.white"
//           fontWeight="bold"
//           style={{ lineHeight: '1rem', fontSize: 22 }}
//         >
//           {appLabel}
//         </Typography>
//       </div>
//     </Grid>
//   );
// };

function MobileAppSection() {
  const { t } = useTranslation('mainpage');

  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  return (
    <div
      style={{
        display: 'grid',
        placeItems: 'center',
        margin: isMobile ? '4rem 0 3rem 0' : '7.5rem 0',
      }}
    >
      <Typography variant="h4" color="secondary.main">
        {t('Mobile app')}
      </Typography>
      <Typography
        color="custom.white"
        textAlign="center"
        style={{
          fontSize: isMobile ? 36 : 80,
          lineHeight: isMobile ? '40px' : '96px',
          margin: isMobile ? '1rem 0 1.5rem 0' : '1rem 0 2rem 0',
          fontWeight: 900,
        }}
      >
        {t('Together')}
        <br />
        {t('in every step')}
      </Typography>
      <Typography
        variant="subtitle1"
        textAlign="center"
        color="primary.I"
        style={{ fontSize: 20, lineHeight: '24px' }}
      >
        {t('We want to stay with you wherever you are.')}
        <br />

        {t('Our mobile app is used around the globe.')}
      </Typography>

      <Grid
        container
        justifyContent="center"
        style={{ marginTop: isMobile ? '1.5rem' : '2.5rem' }}
      >
        {/* <AppStorePoster*/}
        {/*  logo={*/}
        {/*    <Image*/}
        {/*      src="/assets/images/LogoApple.png"*/}
        {/*      width="33.17px"*/}
        {/*      height="40px"*/}
        {/*      alt={t('Apples official brand logo')}*/}
        {/*    />*/}
        {/*  }*/}
        {/*  description={t('GET IT ON')}*/}
        {/*  appLabel={t('Google Play')}*/}
        {/* />*/}
        {/* <AppStorePoster*/}
        {/*  logo={*/}
        {/*    <Image*/}
        {/*      src="/assets/images/LogoPlay.png"*/}
        {/*      width="36.05px"*/}
        {/*      height="40px"*/}
        {/*      alt={t('Google Play Stores official brand logo')}*/}
        {/*    />*/}
        {/*  }*/}
        {/*  description={t('Download on the')}*/}
        {/*  appLabel={t('App Store')}*/}
        {/* />*/}
      </Grid>
    </div>
  );
}

function Tweets() {
  return (
    <>
      <div
        style={{
          marginTop: '5rem',
          position: 'absolute',
          left: 0,
          height: 350,
          width: 'calc(100vw - 1rem)',
          overflow: 'hidden',
        }}
      >
        <Grid
          container
          direction="row"
          spacing={2}
          sx={{ width: '100%' }}
          justifyContent="center"
        >
          <Grid item>
            <blockquote className="twitter-tweet" data-dnt="true">
              <a href="https://twitter.com/SvenKamchen/status/1627390722650304514?ref_src=twsrc%5Etfw" />
            </blockquote>
          </Grid>
          <Grid item>
            <blockquote className="twitter-tweet" data-dnt="true">
              <a href="https://twitter.com/Lock_Space_/status/1630626549689876484?ref_src=twsrc%5Etfw" />
            </blockquote>
          </Grid>
          <Grid item>
            <blockquote className="twitter-tweet" data-dnt="true">
              <a href="https://twitter.com/DEFICHAIN_EPIC/status/1626626194933252096?ref_src=twsrc%5Etfw" />
            </blockquote>
          </Grid>
          <Grid item>
            <blockquote className="twitter-tweet" data-dnt="true">
              <a href="https://twitter.com/phigo90/status/1626541911975636993?ref_src=twsrc%5Etfw" />
            </blockquote>
          </Grid>
          <Grid item>
            <blockquote
              className="twitter-tweet"
              data-conversation="none"
              data-dnt="true"
            >
              <a href="https://twitter.com/DanielZirkel/status/1627304629452111872?ref_src=twsrc%5Etfw" />
            </blockquote>
          </Grid>
        </Grid>
      </div>

      <div style={{ height: 400 }} />
    </>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'mainpage',
        'common',
        'sign-up',
      ])),
    },
  };
}
