import { Divider, Grid, useMediaQuery } from '@mui/material';
import getConfig from 'next/config';
import React from 'react';
import { useTranslation } from 'next-i18next';

import Link from '../Link';

const { publicRuntimeConfig } = getConfig();

function MarketingBannerSection({ variant = 'light' }) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  const isLightVariant = variant === 'light';
  if (isMobile) {
    return (
      <Grid
        xs={12}
        justifyContent="space-around"
        alignItems="center"
        flexDirection="column"
        sx={{
          border: ({ palette }) =>
            `2px solid ${
              isLightVariant ? palette.primary.P : palette.primary.D
            }`,
          borderRadius: '5px',
          margin: '3rem 0',
          padding: '1rem 0',
        }}
        container
        item
      >
        <Grid item>
          <Link
            underline="hover"
            href={publicRuntimeConfig.youtube}
            fontWeight="bold"
            color={!isLightVariant && 'custom.white'}
            style={{ fontSize: 16 }}
          >
            {t('Join us on Youtube')}
          </Link>
        </Grid>

        <Grid item>
          <Divider
            variant="middle"
            sx={{
              width: 222,
              height: 2,
              margin: '1rem 0',
              backgroundColor: !isLightVariant && 'secondary.main',
            }}
          />
        </Grid>

        <Grid item>
          <Link
            underline="hover"
            href={publicRuntimeConfig.contactEmail}
            fontWeight="bold"
            color={!isLightVariant && 'custom.white'}
            style={{ fontSize: 16 }}
          >
            {t('Send us a Message')}
          </Link>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      md={8}
      justifyContent="space-around"
      alignItems="center"
      sx={{
        border: ({ palette }) =>
          `2px solid ${isLightVariant ? palette.primary.P : palette.primary.D}`,
        borderRadius: '5px',
        margin: '3rem',
      }}
      container
      item
    >
      <Grid item container md={5} justifyContent="center">
        <Link
          underline="hover"
          href={publicRuntimeConfig.youtube}
          fontWeight="bold"
          color={!isLightVariant && 'custom.white'}
        >
          {t('Join us on Youtube')}
        </Link>
      </Grid>

      <Grid item>
        <Divider
          variant="middle"
          orientation="vertical"
          sx={{
            height: 46,
            width: 2,
            margin: '2rem 0',
            backgroundColor: !isLightVariant && 'secondary.main',
          }}
        />
      </Grid>

      <Grid item container md={5} justifyContent="center">
        <Link
          underline="hover"
          href={publicRuntimeConfig.contactEmail}
          fontWeight="bold"
          color={!isLightVariant && 'custom.white'}
        >
          {t('Send us a Message')}
        </Link>
      </Grid>
    </Grid>
  );
}

export default MarketingBannerSection;
