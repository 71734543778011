import { useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BannerStroke from '../../media/images/BannerStroke.svg';
import BannerStrokeMobileLarge from '../../media/images/BannerStrokeMobile1.svg';
import BannerStrokeMobileSmall from '../../media/images/BannerStrokeMobile2.svg';

function MarketingMotivationBanner({ textAlign = 'center', color }) {
  const { t } = useTranslation('mainpage');
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  if (isMobile) {
    return (
      <div
        style={{
          margin: '2rem 0 1.5rem 0',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <span
          style={{
            fontSize: 36,
            fontWeight: 'bold',
            textAlign: 'center',
            zIndex: 2,
            position: 'relative',
            margin: 0,
          }}
        >
          <BannerStrokeMobileSmall
            style={{
              position: 'absolute',
              left: -5,
              width: 'calc(100% + 20px)',
              bottom: 0,
              zIndex: 1,
            }}
          />
          <span style={{ zIndex: 2, position: 'relative', color }}>
            {t('Krypto')}
          </span>
        </span>

        <span
          style={{
            fontSize: 36,
            fontWeight: 'bold',
            textAlign: 'center',
            zIndex: 2,
            position: 'relative',
            margin: 0,
          }}
        >
          <BannerStrokeMobileLarge
            style={{
              position: 'absolute',
              left: -10,
              width: 'calc(100% + 20px)',
              bottom: 0,
              zIndex: 1,
            }}
          />
          <span style={{ zIndex: 2, position: 'relative', color }}>
            {t('Steuererklärung')}
          </span>
        </span>

        <span
          style={{
            fontSize: 36,
            fontWeight: 'bold',
            color,
            textAlign: 'center',
            zIndex: 2,
            position: 'relative',
            margin: 0,
          }}
        >
          {t('in wenigen Minuten.')}
        </span>
      </div>
    );
  }

  return (
    <div style={{ margin: '4rem 0 1.5rem 0' }}>
      <span
        style={{
          fontSize: 80,
          fontWeight: 'bold',
          zIndex: 2,
          position: 'relative',
          margin: 0,
          textAlign,
          width: textAlign === 'center' ? '100%' : undefined,
          display: textAlign === 'center' ? 'inline-block' : undefined,
        }}
      >
        <BannerStroke
          style={{
            position: 'absolute',
            left: textAlign === 'center' ? -20 : 0,
            width: '105%',
            bottom: '-0.5rem',
            zIndex: 1,
          }}
        />
        <span style={{ zIndex: 2, position: 'relative', color }}>
          {t('Krypto Steuererklärung')}
        </span>
      </span>
      <div />

      <span
        style={{
          fontSize: 80,
          fontWeight: 'bold',
          color,
          textAlign,
          width: textAlign === 'center' ? '100%' : undefined,
          display: textAlign === 'center' ? 'inline-block' : undefined,
          margin: '-0.5rem 0 0 0',
        }}
      >
        {t('in wenigen Minuten.')}
      </span>
    </div>
  );
}

export default MarketingMotivationBanner;
